import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Alert, Collapse } from 'reactstrap';

import { Auth } from 'Service';
import { _, Utils, Http, API, AuthHelp, Socket } from 'Helpers';
import { MessageBox } from 'Components';
import { CONSTANT, SocketEvent } from 'Constants';

import { connect } from "react-redux";
import { loginOpen, LoaderShow, LoaderHide, UpdateProfile, SlipToggle, updateWinloss } from '../../Redux/Reducers';
import { actionBetSlipRule, actionClearBetSlipWindow } from '../../Redux/FixtureList_Redux/BetsRule/action';

import { withTranslation } from 'react-i18next';
import { BetSettingModal } from 'Modals';
import { Clock } from 'Components';
class Betslip extends Component {
  clockRef = null;
  constructor(props) {
    super(props);
    const { page_id } = props;
    this.state = {
      posting: false,
      BetslipType: page_id,
      isSingleBet: true,
      AppMasterData: Utils.getMasterData(),
      SelectedOdds: {},
      MultipleBetStake: Utils.getMultiBetSlip(CONSTANT.BETSLIP[page_id]),
      BetPosting: false,
      betSlipActive: "",
      global_error_msz: '',
      BetSettingModalShow: false,
      showTimeOnLoader: 0,
      userSpoartSettings: [],
      defaultSecond: 10000,
      // defaultSecond: 10000000,
      defaultSecondShow: false,
      changedefaultSecond: 10000,
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
    }
    this.winlossHandler = this.winlossHandler.bind(this);
    this.setClockRef = this.setClockRef.bind(this);
    // this.winlossHandler = _.debounce(this.winlossHandler.bind(this), 0);
  }

  setClockRef(ref) {
    // When the `Clock` (and subsequently `Countdown` mounts
    // this will give us access to the API
    this.clockRef = ref;
  }

  sockeListener = () => {
    const _this = this
    Socket.on(SocketEvent.MARKET, function (res) {
      _this.SocketMarkethandler(res)
    })
  }
  removeSockeListener = () => {
    Socket.off(SocketEvent.MARKET)
  }

  SocketMarkethandler = (res = {}) => {
    const { SelectedOdds } = this.state


    if (!_.isEmpty(SelectedOdds)) {
      let newSelectedOdds = {}
      _.map(SelectedOdds, (item, idx) => {
        let new_odd_list = item.odd_list;
        let new_picked_odd = item.picked_odd
        if (!item.is_unmatch_bet && item.fixture_id === res.fixture_id) {
          if (new_picked_odd.bet_id === res.bet_id) {
            new_picked_odd = { ...new_picked_odd, ...res }
          }
          newSelectedOdds = {
            ...newSelectedOdds, [idx]: {
              ...item,
              odd_list: new_odd_list,
              picked_odd: new_picked_odd
            }
          }
        } else {
          newSelectedOdds = { ...newSelectedOdds, [idx]: item }
        }
      })
      if (!_.isEmpty(newSelectedOdds)) {
        this.setState({
          SelectedOdds: newSelectedOdds
        }, () => {
          Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
        })
      }
    }
  }

  SocketGlobalSettingHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    this.setState({
      AppMasterData: { ...AppMasterData, ...res }
    });
  }

  SocketSettingSportHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    _.map(AppMasterData.sports_list.exchange_betting, (item, index) => {
      if (Number(item.sports_id) === Number(res.sports_id)) {
        AppMasterData.sports_list.exchange_betting[index].fancy_delay = res.fancy_delay;
        AppMasterData.sports_list.exchange_betting[index].match_delay = res.match_delay;
      }
    })
    this.setState({
      AppMasterData: AppMasterData
    })
  }

  SocketUserSettingUpdate = (res = {}) => {
    this.setState({ userSpoartSettings: res })
  }

  // Life cycle
  componentDidMount() {
    const { SelectedOdds } = this.props;
    const { AppMasterData } = this.state;
    const currentUser = Auth.currentUserValue;
    const _this = this;
    if (currentUser && currentUser.sport_settings)
      this.setState({ userSpoartSettings: currentUser.sport_settings })

    this.setState({
      SelectedOdds: SelectedOdds,
      successMessage: '',
    }, () => {

      const currentUser = Auth.currentUserValue;
      if ((this.props.fancy_betslip == "fancy_betting") && currentUser && currentUser.fancy_stack)
        this.setState({ BetslipStack: currentUser.fancy_stack.split(',') });
      else if ((this.props.bookmaker_betslip == "bookmaker_betslip") && currentUser && currentUser.bookmaker_stack)
        this.setState({ BetslipStack: currentUser.bookmaker_stack.split(',') });
      else if (currentUser && currentUser.odds_stack)
        this.setState({ BetslipStack: currentUser.odds_stack.split(',') });

      if (this.props.fancy_betslip == "fancy_betting")
        this.switchBetting(CONSTANT.FANCY_BETTING)

      const { SelectedOdds } = this.state
      if (!_.isEmpty(SelectedOdds)) {
        setTimeout(() => {
          this.winlossHandler(0)
        }, 500)
      }
    })
    Socket.on(SocketEvent.GLOBAL_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketGlobalSettingHandler(res)
      }
    })
    Socket.on(SocketEvent.SETTING_SPORTS, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketSettingSportHandler(res)
      }
    })
    Socket.on(SocketEvent.GLOBAL_SEARCH_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketUserSettingUpdate(res)
      }
    })
    // this.sockeListener()
    // setTimeout(() => {
    //   this.SocketMarkethandler()
    // }, 3000)

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.betslip_lastupdate !== this.props.betslip_lastupdate) {
      const { page_id } = this.props;
      const { AppMasterData } = this.state;

      this.setState({
        SelectedOdds: nextProps.fancy_betslip === CONSTANT.FANCY_BETTING ? nextProps.SelectedOddsFancy : nextProps.SelectedOdds,
        BetslipType: nextProps.fancy_betslip === CONSTANT.FANCY_BETTING ? nextProps.fancy_betslip : page_id,
      }, () => {
        const currentUser = Auth.currentUserValue;
        if ((nextProps.fancy_betslip == "fancy_betting") && currentUser && currentUser.fancy_stack)
          this.setState({ BetslipStack: currentUser.fancy_stack.split(',') });
        else if ((nextProps.bookmaker_betslip == "bookmaker_betslip") && currentUser && currentUser.bookmaker_stack)
          this.setState({ BetslipStack: currentUser.bookmaker_stack.split(',') });
        else if (currentUser && currentUser.odds_stack)
          this.setState({ BetslipStack: currentUser.odds_stack.split(',') });
      });
    }
  }

  // Handlers
  switchBetting = (BetslipType) => {
    this.setState({
      BetslipType,
      isSingleBet: true
    }, () => {
      const currentUser = Auth.currentUserValue;
      if ((BetslipType == "fancy_betting") && currentUser && currentUser.fancy_stack)
        this.setState({ BetslipStack: currentUser.fancy_stack.split(',') });
      else if ((BetslipType == "bookmaker_betslip") && currentUser && currentUser.bookmaker_stack)
        this.setState({ BetslipStack: currentUser.bookmaker_stack.split(',') });
      else if (currentUser && currentUser.odds_stack)
        this.setState({ BetslipStack: currentUser.odds_stack.split(',') });

      this.setState({
        SelectedOdds: Utils.getBetSlip(BetslipType)
      })
    })
  }
  // Single Bet handler
  stackChangeHandle = idx => evt => {
    const re = /^\d*\.?(?:\d{1,2})?$/;

    let { SelectedOdds } = this.state
    let value = evt.target.value;
    if (!re.test(value)) return SelectedOdds[idx].stack_value.stack_value
    SelectedOdds[idx].stack_value = (value === '' || re.test(value)) ? value : SelectedOdds[idx].stack_value.stack_value
    SelectedOdds[idx].invalid_bet = 0
    SelectedOdds[idx].return_stake_msz = ''
    SelectedOdds[idx].return_msz = ''
    this.setState({ SelectedOdds }, () => {
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
  }


  winlossHandler(idx) {

    const { updateWinloss } = this.props
    let { SelectedOdds, BetslipType } = this.state
    let selected_odds = SelectedOdds[idx]
    let stack_value = selected_odds.stack_value
    // if(stack_value === '') return

    let bookmaker_type = CONSTANT.BOOKMAKER_TYPE[BetslipType];
    let betting_type = selected_odds.picked_odd.betting_type;   // 1 = back , 2= Lay
    let price = selected_odds.picked_odd.price;
    let stake = stack_value;
    var payout = stake * price;

    if (bookmaker_type === 2) { // In case Fancy bet
      //pp =  Potential Profit
      var pp = payout - stake; // in case of back betting;
      var liability = stake; // in case of back
      if (betting_type === 2 && bookmaker_type !== 3)   // In case OF lay
      {
        pp = stake;
        liability = payout - stake;
      }
      updateWinloss({
        lastupdate: new Date().valueOf(),
        type: 1,
        market_event_id: selected_odds.market_event_id,
        session_id: selected_odds.picked_odd.session_id,
        selectionId: selected_odds.picked_odd.selectionId,
        stack: stack_value,
        betting_type: selected_odds.picked_odd.betting_type,
        pp: pp,
        liability: liability,
        bookmaker_type: bookmaker_type,
        is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
        is_place_bet: false
      })
    }
  }

  handleKey = (evt, idx) => {
    let { SelectedOdds } = this.state
    const input = evt.target;
    const BaseValue = 10;
    const value = input.value === '' ? 0 : input.value;

    let item = SelectedOdds[idx]
    if (evt.keyCode === 38) {
      let newValue = item.stack_value === '' || item.stack_value < BaseValue ? BaseValue : (value === 0 ? BaseValue : Number(value) + 1)
      SelectedOdds[idx].stack_value = newValue

      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''

      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    } else if (evt.keyCode === 40) {
      let newValue = item.stack_value <= BaseValue ? item.stack_value : Number(value) - 1
      SelectedOdds[idx].stack_value = newValue;

      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''

      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
  }

  marketChangeHandle = idx => evt => {
    // const re = /^[0-9\.\b]+$/;
    const re = /^\d*\.?(?:\d{1,2})?$/;

    let { SelectedOdds } = this.state;
    let value = evt.target.value;
    let item = SelectedOdds[idx]
    item.picked_odd['price'] = (value === '' || re.test(value)) ? value : (item.picked_odd.price)

    item.invalid_bet = 0
    item.return_stake_msz = ''
    item.return_msz = ''

    let newSelectedOdds = {
      ...item,
      is_unmatch_bet: Number(item.picked_odd_price) === Number(value) ? false : true
    };
    SelectedOdds[idx] = newSelectedOdds
    this.setState({ SelectedOdds }, () => {
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
  }

  StackClickHandle = (idx, value) => {
    let { SelectedOdds } = this.state;
    let item = SelectedOdds[idx]

    // item.stack_value = _.isUndefined(item.stack_value) ? Number(value) : Number(item.stack_value) + Number(value)
    item.stack_value = Number(value)

    item.invalid_bet = 0
    item.return_stake_msz = ''
    item.return_msz = ''

    SelectedOdds[idx] = item;
    this.setState({ SelectedOdds }, () => {
      this.winlossHandler(0)
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
    this.setState({
      betSlipActive: value
    })
  }

  // Multiple Bet handler
  multipleBetStackHandle = evt => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state
    let value = evt.target.value;
    const re = /^[0-9\.\b]+$/;
    MultipleBetStake = (value === '' || re.test(value)) ? value : MultipleBetStake
    this.setState({ MultipleBetStake }, () => {
      Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
    });
  }

  multipleBetHandleKey = (evt) => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state
    const input = evt.target;
    const BaseValue = 10;
    const value = input.value === '' ? 0 : input.value;
    if (evt.keyCode === 38) {
      let newValue = (MultipleBetStake === '' || MultipleBetStake < BaseValue) ? BaseValue : (value === 0 ? BaseValue : Number(value) + 1)
      this.setState({ MultipleBetStake: newValue }, () => {
        Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
      });
    } else if (evt.keyCode === 40) {
      let newValue = MultipleBetStake <= BaseValue ? MultipleBetStake : Number(value) - 1
      this.setState({ MultipleBetStake: newValue }, () => {
        Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
      });
    }
  }

  multipleBetStackClickHandle = (value) => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state;
    let newValue = _.isUndefined(MultipleBetStake) ? Number(value) : Number(MultipleBetStake) + Number(value)
    this.setState({ MultipleBetStake: newValue }, () => {
      Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
    });
  }

  // Other
  ModeUpdate = () => {
    this.setState({ global_error_msz: '' })
    setTimeout(() => {
      const { SelectedOdds } = this.props
      if (_.size(SelectedOdds) === 1) {
        this.setState({ isSingleBet: true });
      }
    }, 100)
  }

  totalValue = (type) => {
    const { SelectedOdds } = this.state
    let obj = {
      stake: [],
      odd: []
    }
    if (type === 'stake') {
      _.forEach(SelectedOdds, item => {
        obj.stake.push(_.isUndefined(item.stack_value) ? 0 : Number(item.stack_value))
      })
    } else if (type === 'odd') {
      _.forEach(SelectedOdds, item => {
        obj.odd.push(Number(item.picked_odd.price))
      })
    }
    return _.round(_.sum(obj[type]), 2)
  }


  isDuplicateBetSlip = () => {
    let { SelectedOdds } = this.state;
    let _arr = []
    _.forEach(SelectedOdds, item => {
      _arr.push(item.fixture_id)
    })
    return !(_arr.length === new Set(_arr).size);
  }

  isLayMultiBet = () => {
    let { SelectedOdds } = this.state;
    let _lay = []
    _.forEach(SelectedOdds, item => {
      if (item.picked_odd.betting_type === 2) {
        _lay.push(item.picked_odd.betting_type)
      }
    })
    return _lay.length > 0;
  }

  isValidate = () => {
    const { SelectedOdds, isSingleBet, MultipleBetStake, BetslipType } = this.state;
    let arr = []
    _.forEach(SelectedOdds, item => {
      if (isSingleBet) {
        if (Number(item.market_type) === 2 && Number(Utils.TwoDecimalVal(item.picked_odd.price)) === 1)
          item.picked_odd.price = Number(Utils.ThreeDecimalVal(item.picked_odd.price)) - 1;
        if (Number(item.market_type) === 1 && Number(Utils.TwoDecimalVal(item.picked_odd.price)) === 1)
          item.picked_odd.price = Number(Utils.TwoDecimalVal(item.picked_odd.price)) - 1;

        if ((!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0) && Number(item.picked_odd.price) > 0) {
          arr.push(item)
        }
      } else {
        if (Number(item.picked_odd.price) > 0) {
          arr.push(item)
        }
      }
    })
    return isSingleBet ? (arr.length === 0) : (arr.length === 0 || (MultipleBetStake === '' || MultipleBetStake === 0))
  }

  PlaceBetHandlerPrev = () => {
    const { LoaderShow, LoaderHide, UpdateProfile, updateWinloss } = this.props
    let { SelectedOdds, isSingleBet, MultipleBetStake, BetslipType } = this.state;
    let bet_data = []
    let not_stack = {}
    let extra_data = {}
    _.forEach(SelectedOdds, (item, key) => {
      if (
        ((isSingleBet && Number(item.picked_odd.price) > 0 && (!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0))
          ||
          (!isSingleBet && Number(item.picked_odd.price) > 0))
      ) {
        if (item.invalid_bet !== 1) {
          extra_data = {
            "picked_odd": item.picked_odd,
            "betOnRun": item.picked_odd.runner_name ? item.picked_odd.runner_name : _.find(JSON.parse(item.runners), (data, z) => {
              if (item.picked_odd.selectionId == data.selectionId)
                return data.runnerName

            }),
            "item": item,
            "whatWeGet": item.picked_odd.betting_type === 1 ?
              (
                BetslipType !== CONSTANT.FANCY_BETTING ?
                  (_.round(Number(Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) * Number(item.stack_value), 2))
                  :
                  (_.round((Number(item.stack_value) * (Number(item.picked_odd.back_size_1) / 100)), 2))
              )
              :
              (
                BetslipType !== CONSTANT.FANCY_BETTING ?
                  (_.round((Number(Utils.TwoDecimalVal(item.picked_odd.price)) * Number(item.stack_value)) - Number(item.stack_value), 2))
                  :
                  (_.round((Number(item.stack_value) * (Number(item.picked_odd.lay_size_1) / 100)), 2))
              ),
          }
          bet_data = [...bet_data, {
            "fixture_id": item.event_id,
            "market_bet_id": item.market_bet_id,
            "betting_type": item.picked_odd.betting_type,
            "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
            ...(BetslipType === CONSTANT.FANCY_BETTING ? { "line": item.picked_odd.price } : { "line": item.picked_odd.line }),
            ...(BetslipType === CONSTANT.FANCY_BETTING ? { "price": "2" } : { "price": item.picked_odd.price }),
            ...(isSingleBet && { "stake": item.stack_value })
          }]
        } else {
          not_stack = { ...not_stack, [key]: { ...item } }
        }
      } else {
        not_stack = { ...not_stack, [key]: { ...item, return_stake_msz: CONSTANT.BLANK_STAKE, return_msz: '' } }
      }
    })
    let param = {
      bet_data,
      "bookmaker_type": CONSTANT.BOOKMAKER_TYPE[BetslipType], // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
      "bet_type": isSingleBet ? "1" : "2",          // 1 = Single,  2= Multiple
      ...(!isSingleBet && { "stake": MultipleBetStake })
    }
    this.setState({ BetPosting: true, global_error_msz: '' }, () => {
      LoaderShow()
    })
    this.getMaxDelay().then(res => {
      Http.post(API.PLACE_BET, param, true).then(response => {
        this.props.actionBetSlipRule(response)
        let setDataOfBet = { response, param, extra_data }
        localStorage.setItem('setDataOfBet', JSON.stringify(setDataOfBet));
        this.setState({ BetPosting: false }, () => {
          Auth.updateBalance(response.data.balance, response.data.exposure)
          updateWinloss({
            lastupdate: new Date().valueOf(),
            type: 0,
            win_loss: response.data.win_loss,
            fexposure: response.data.fexposure,
            market_event_id: param.market_event_id,
            session_id: param.market_event_id,
            bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
            is_unmatch_bet: param.is_custom_odds === 1 ? true : false,
            event_id: param.event_id,
            is_place_bet: param.is_custom_odds === 1 ? true : false,
            bet_place: true
          })
          UpdateProfile()
          LoaderHide()
          this.clearAllBet(BetslipType)
          setTimeout(() => {
            if (!_.isEmpty(not_stack)) {
              this.setState({ SelectedOdds: not_stack }, () => {
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
              })
            }
          }, 100)
          this.setState({ defaultSecondShow: true, defaultSecond: this.state.changedefaultSecond })
        })
      }).catch(err => {
        this.props.actionBetSlipRule(err)
        if (err.response_code === 500) {
          let newSelectedOdds = {}
          _.forEach(SelectedOdds, (item, key) => {
            _.forEach(err.error.odds_changed, obj => {
              if (item.market_bet_id === obj.market_bet_id) {
                item.picked_odd.price = obj.price
                // item.return_msz = obj.return_msz
                item.return_stake_msz = ''
                item.return_msz = CONSTANT.BETSLIP_RETURN_MSZ
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                this.props.actionBetSlipRule({ message: CONSTANT.BETSLIP_RETURN_MSZ })
              }
            })
            _.forEach(err.error.invalid_bet, obj => {
              if (item.market_bet_id === obj.market_bet_id) {
                item.picked_odd.price = obj.price
                item.invalid_bet = 1
                item.return_msz = obj.return_msz
                item.return_stake_msz = ''
                // item.return_msz = CONSTANT.BETSLIP_INVALID_BET_MSZ
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                this.props.actionBetSlipRule({ message: obj.return_msz })
              }
            })
            if (
              !(((isSingleBet && Number(item.picked_odd.price) > 0 && (!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0))
                ||
                (!isSingleBet && Number(item.picked_odd.price) > 0)))
            ) {
              item.return_stake_msz = CONSTANT.BLANK_STAKE;
              this.props.actionBetSlipRule({ message: CONSTANT.BLANK_STAKE })
            }

            newSelectedOdds = { ...newSelectedOdds, [key]: item }
          })
          if (_.isEmpty(newSelectedOdds)) {
            this.props.actionBetSlipRule(err)
          } else {
            // Utils.notify(CONSTANT.PARTIALLY_SUBMIT_BETSLIP, '')
            this.setState({ SelectedOdds: newSelectedOdds }, () => {
              Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
            })
          }
        } else {
          this.props.actionBetSlipRule({ message: err.global_error })
          AuthHelp.checkAuth(err)
        }
        this.setState({
          BetPosting: false,
          global_error_msz: err.global_error
        }, () => {
          this.setState({ defaultSecondShow: true, defaultSecond: this.state.changedefaultSecond })
          LoaderHide()
        })
      });
    })
  }
  parseErrorMessage = (response) => {
    var errorMsg = response.message !== "" ? response.message : response.global_error;
    if (errorMsg === "") {
      for (var key in response.error) {
        if (key === 'odds_changed' || key === 'invalid_bet') {
          break;
        }
        errorMsg = response.error[key];
        if (errorMsg !== "") {
          break;
        }
      }
    }
    return errorMsg
  }
  PlaceBetHandler = () => {
    const { LoaderShow, LoaderHide, UpdateProfile, updateWinloss } = this.props
    let { SelectedOdds, BetslipType } = this.state;
    let param = {}
    let extra_data = {}
    let new_selectedodds = _.map(SelectedOdds, item => {
      extra_data = {
        "picked_odd": item.picked_odd,
        "betOnRun": item.picked_odd.runner_name ? item.picked_odd.runner_name : _.find(JSON.parse(item.runners), (data, z) => {
          if (item.picked_odd.selectionId == data.selectionId)
            return data.runnerName

        }),
        "item": item,
        "whatWeGet": item.picked_odd.betting_type === 1 ?
          (
            BetslipType !== CONSTANT.FANCY_BETTING ?
              (_.round(Number(Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) * Number(item.stack_value), 2))
              :
              (_.round((Number(item.stack_value) * (Number(item.picked_odd.back_size_1) / 100)), 2))
          )
          :
          (
            BetslipType !== CONSTANT.FANCY_BETTING ?
              (_.round((Number(Utils.TwoDecimalVal(item.picked_odd.price)) * Number(item.stack_value)) - Number(item.stack_value), 2))
              :
              (_.round((Number(item.stack_value) * (Number(item.picked_odd.lay_size_1) / 100)), 2))
          ),
      }
      param = {
        "event_id": item.event_id,
        "betting_type": item.picked_odd.betting_type,
        "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
        "stake": item.stack_value,
        "bookmaker_type": CONSTANT.BOOKMAKER_TYPE[BetslipType],     // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
        "market_event_id": item.market_event_id,
        "other_price": item?.picked_odd?.other_price ? item?.picked_odd?.other_price : 0,
        ...(BetslipType === CONSTANT.FANCY_BETTING ? { "market_event_id": item.picked_odd.session_id } : { "market_event_id": item.market_event_id }),
        ...(BetslipType !== CONSTANT.FANCY_BETTING && { "selection_id": item.picked_odd.selectionId }),
        ...(BetslipType === CONSTANT.FANCY_BETTING ? { "price": "2" } : { "price": item.picked_odd.price }),
        ...(BetslipType === CONSTANT.FANCY_BETTING ? { "line": item.picked_odd.price } : { "line": "" }),
      }
      return { ...item, return_msz: '' }
    })


    this.setState({
      SelectedOdds: new_selectedodds,
      BetPosting: true,
      global_error_msz: ''
    }, () => {
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      LoaderShow()
    })
    this.getMaxDelay().then(res => {
      Http.post(API.PLACE_BET, param, true).then(response => {
        let setDataOfBet = { response, param, extra_data }
        localStorage.setItem('setDataOfBet', JSON.stringify(setDataOfBet));
        this.props.actionBetSlipRule(response)
        this.setState({
          BetPosting: false,
        }, () => {
          Auth.updateBalance(response.data.balance, response.data.exposure)
          updateWinloss({
            lastupdate: new Date().valueOf(),
            type: 0,
            win_loss: response.data.win_loss,
            fexposure: response.data.fexposure,
            market_event_id: param.market_event_id,
            session_id: param.market_event_id,
            bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
            is_unmatch_bet: param.is_custom_odds === 1 ? true : false,
            event_id: param.event_id,
            is_place_bet: param.is_custom_odds === 1 ? true : false,
            bet_place: true
          })
          UpdateProfile()
          this.clearAllBet(BetslipType)
          const { closeMarketBetSlip } = this.props
          closeMarketBetSlip();
        })
        LoaderHide()
        this.setState({ defaultSecondShow: true, defaultSecond: this.state.changedefaultSecond })

      }).catch(err => {
        this.props.actionBetSlipRule(err)
        if (!_.isEmpty(err.error)) {
          const { odds_changed = {}, invalid_bet = {} } = err.error
          if (err.response_code === 500) {
            let newSelectedOdds = {}
            _.forEach(SelectedOdds, (item, key) => {

              if (invalid_bet == '' && odds_changed.return_msz === "Bhav changed, try again") {
                item.return_msz = invalid_bet.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                return
              }


              if (odds_changed !== '' && item.market_event_id === odds_changed.market_event_id) {
                item.picked_odd.price = odds_changed.price
                item.return_msz = odds_changed.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                this.props.actionBetSlipRule({ message: odds_changed.return_msz })
              }

              if (invalid_bet !== '' && item.market_event_id === invalid_bet.market_event_id) {
                item.return_msz = invalid_bet.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                this.props.actionBetSlipRule({ message: invalid_bet.return_msz })
              }
              newSelectedOdds = { ...newSelectedOdds, [key]: item }
            })
            if (_.isEmpty(newSelectedOdds)) {
              this.props.actionBetSlipRule(err)
            } else {
              this.setState({ SelectedOdds: newSelectedOdds }, () => {
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
              })
            }
          } else {
            this.setState({ global_error_msz: this.parseErrorMessage(err) })
            AuthHelp.checkAuth(err)
          }
        }

        LoaderHide()
        this.setState({
          BetPosting: false,
          global_error_msz: this.parseErrorMessage(err)
        }, () => {
          this.setState({ defaultSecondShow: true, defaultSecond: this.state.changedefaultSecond })
        })
      });
    })
  }

  clearAllBet = (BetslipType) => {
    const { clearAllBet } = this.props
    Utils.setBetSlip(BetslipType, {})
    clearAllBet(BetslipType)
    this.setState({
      MultipleBetStake: '',
      global_error_msz: '',
    }, () => {
      this.setState({
        SelectedOdds: Utils.getBetSlip(BetslipType),
        BetslipType: BetslipType
      })
    })
  }
  handleRemoveSlip = (key) => {
    const { handleRemoveSlip, _fancyHandleRemoveSlip, updateWinloss } = this.props
    const { BetslipType, SelectedOdds } = this.state
    this.setState({
      global_error_msz: '',
    })
    // if (BetslipType !== CONSTANT.FANCY_BETTING) {
    if (BetslipType) {
      let selected_odds = SelectedOdds[key]
      updateWinloss({
        lastupdate: new Date().valueOf(),
        type: 2,
        market_event_id: selected_odds.market_event_id,
        session_id: selected_odds.picked_odd.session_id,
        bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
        is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
        is_place_bet: false
      })

      handleRemoveSlip(key)
      this.ModeUpdate()
    } else {
      _fancyHandleRemoveSlip(key)
    }
  }

  clearBetSlipWindow = () => {
    this.handleRemoveSlip(0)
    this.setState({ betSlipActive: "" })
    const { closeMarketBetSlip } = this.props;
    closeMarketBetSlip();
  }

  //
  getMaxDelay = () => {
    const { EventDetails } = this.props;
    const { BetslipType, AppMasterData, SelectedOdds, userSpoartSettings } = this.state;
    let timeArr = 0;
    _.forEach(SelectedOdds, slip => {
      _.forEach(AppMasterData.sports_list.exchange_betting, item => {
        if (Number(item.sports_id) === Number(slip.sports_id)) {
          if (Number(slip.market_type) === 2)
            timeArr = Number(item.bookmaker_delay);
          else if (BetslipType === CONSTANT.FANCY_BETTING)
            timeArr = Number(item.fancy_delay);
          else if (Number(slip.market_type) === 1)
            timeArr = Number(item.match_delay);
          else
            timeArr = Number(item.match_delay);
        }
      })
      if (!_.isEmpty(EventDetails)) {
        if (Number(slip.sports_id) === Number(EventDetails.sports_id)) {
          if ((BetslipType === CONSTANT.FANCY_BETTING) && EventDetails.fancy_delay)
            timeArr = Number(EventDetails.fancy_delay)
          else if ((Number(slip.market_type) === 2) && EventDetails.bookmaker_delay)
            timeArr = Number(EventDetails.bookmaker_delay)
          else if ((Number(slip.market_type) === 2) && EventDetails.match_delay)
            timeArr = Number(EventDetails.match_delay)
          else
            timeArr = Number(EventDetails.match_delay)
        }
      }
      if (!_.isEmpty(userSpoartSettings)) {
        _.forEach(userSpoartSettings, setting => {
          if ((Number(setting.sports_id) === Number(slip.sports_id)) && (Number(setting.setting_type) === 1)) {
            if (BetslipType === CONSTANT.FANCY_BETTING)
              timeArr = Number(setting.fancy_delay);
            else if (Number(slip.market_type) === 2)
              timeArr = Number(setting.bookmaker_delay);
            else if (Number(slip.market_type) === 1)
              timeArr = Number(setting.match_delay);
            else
              timeArr = Number(setting.match_delay);
          }
        })
      }
      if (this.props.delay)
        timeArr = Number(this.props.delay)
    })

    this.setState({ showTimeOnLoader: timeArr, changedefaultSecond: (timeArr * 1000) })

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, timeArr * 1000)
    })
  }
  modalHide = () => {
    this.clockRef.start();
    this.setState({
      BetSettingModalShow: false,
    })
  }
  modalShow = (name) => {
    this.clockRef.pause();
    this.setState({
      BetSettingModalShow: true
    })
  }

  modalUpdate = () => {
    this.setState({
      BetSettingModalShow: false,
    }, () => {
      this.clockRef.start();
    })
  }

  render() {
    const { t } = this.props;

    const {
      isSingleBet,
      SelectedOdds,
      BetslipStack,
      BetslipType,
      MultipleBetStake,
      BetPosting,
      global_error_msz,
      BetSettingModalShow,
      defaultSecond,
      defaultSecondShow,
      isThemeGradient
    } = this.state
    const BetSettingModalProps = {
      isOpen: BetSettingModalShow,
      BetslipStack: BetslipStack,
      stake_type: this.props.SelectedOdds && !_.isEmpty(this.props.SelectedOdds) && this.props.SelectedOdds[0].is_bookmaker && Number(this.props.SelectedOdds[0].is_bookmaker) === 1 ? "bookmaker_betslip" : BetslipType,
      toggle: this.modalHide,
      callBack: this.modalUpdate
    }

    const currentUser = Auth.currentUserValue
    const AvalStacks = ({ idx }) => {
      return (
        // <div className="betslip--footer text-right">
        <div className="betslip--footer text-center">
          {
            _.map(BetslipStack, (item, key) => {
              return (
                item !== null &&
                <a className={`my-1 ${this.state.betSlipActive === item ? " active-stack " : ""} ${isThemeGradient && this.state.betSlipActive != item ? " bg-radial-1 " : ""}`} onClick={() => this.StackClickHandle(idx, item)} {...{ key }}>{item}</a>
              )
            })

          }
          <a className={"my-1 " + (isThemeGradient ? "bg-radial-1" : "")} onClick={() => this.modalShow('BetSettingModalProps')}><span className="icon-cog" /></a>
          {BetSettingModalShow && <BetSettingModal {...BetSettingModalProps} />}
        </div >
      )
    }
    return (
      !_.isEmpty(SelectedOdds) &&
      <div {...{ className: `betslip-wrap${_.isEmpty(SelectedOdds) ? ' blank' : ''}${CONSTANT.ONLY_SINGLE_BET ? ' only-single-bet' : ''} ` }}>
        <div className="betslip-container position-relative">
          {
            _.isEmpty(SelectedOdds) ?
              <MessageBox heading={t(CONSTANT.BETSLIP_BLANK_HEADING)} paragraph={t(CONSTANT.BETSLIP_BLANK_PARAGRAPH)} />
              :
              <div {...{ className: `betslip - list ${CONSTANT.IS_MULTIBET_ALLOW ? '' : 'm-t'} ${(!_.isEmpty(SelectedOdds) && !isSingleBet) ? 'mulitple-bet' : ''} ` }}>
                {
                  _.map(SelectedOdds, (item, key) => {
                    return (
                      <div  {...{ className: `betslip - item ${BetslipType === CONSTANT.FANCY_BETTING ? (Number(item.picked_odd.betting_type) === 1 ? "lagai" : "khai") : (Number(item.picked_odd.betting_type) === 1 ? "lagai" : "khai")} ${(item.picked_odd.betting_type === 2) ? (BetslipType === CONSTANT.FANCY_BETTING ? '' : 'lay-slip') : (BetslipType === CONSTANT.FANCY_BETTING ? 'lay-slip' : '')} `, key }}>
                        <Row className='text-center new_betslip_body align-items-center'>
                          <Col className='col-12 text-left px-2 py-0 pl-4'>Stake Limit: {BetslipType === CONSTANT.FANCY_BETTING ? item.picked_odd.min_fancy_stake : item.min_odd} - {BetslipType === CONSTANT.FANCY_BETTING ? item.picked_odd.max_fancy_stake : item.max_odd} Max Profit: {BetslipType === CONSTANT.FANCY_BETTING ? item.picked_odd.max_profit : item.max_profit} </Col>
                          <Col className='col-4 text-left px-2 pl-4'>
                            {
                              BetslipType === CONSTANT.FANCY_BETTING ? (
                                <span className="betslip_runner_name">{item.picked_odd.runner_name}</span>
                              ) : (
                                _.map(JSON.parse(item.runners), (runner, index) => {
                                  return (
                                    (item.market_bet_id == runner.selectionId) &&
                                    <span className="betslip_runner_name" key={index}>{runner.runnerName}</span>
                                  )
                                })
                              )
                            }
                          </Col>
                          <Col className='col-4 px-2'>
                            {
                              item.picked_odd.betting_type === 1 ?
                                <div className='return-value-col'>
                                  <div className="return--small">{t('Return')}</div>
                                </div>
                                :
                                <div className='return-value-col'>
                                  <div className="return--small">{t('Liability')}</div>
                                </div>
                            }
                          </Col>
                          <Col className='col-2 px-2'>
                            {BetslipType === CONSTANT.FANCY_BETTING ? (Number(item.picked_odd.betting_type) === 1 ? "YES" : "NO") : (Number(item.picked_odd.betting_type) === 1 ? "LAGAI" : "KHAI")}
                          </Col>
                          <Col className='col-2 px-2'>P-TIME</Col>
                        </Row>
                        <Row className='text-center new_betslip_body align-items-center'>
                          <Col className='col-4 text-left p-1 pl-4 amount'>
                            <input
                              type="text"
                              placeholder='Amount'
                              className={'stake-input ' + (BetslipType !== CONSTANT.FANCY_BETTING ? (Number(item.picked_odd.betting_type) === 1 ? " back-odds" : " lay-odds") : (Number(item.picked_odd.betting_type) === 1 ? " back-odds" : " lay-odds"))}
                              value={item.stack_value}
                              onKeyDown={(e) => this.handleKey(e, key)}
                              onChange={this.stackChangeHandle(key)}
                              onKeyUp={() => this.winlossHandler(key)}
                            />
                          </Col>
                          <Col className='col-4 p-1'>
                            {
                              item.picked_odd.betting_type === 1 ?
                                <div className='return-value-col'>
                                  <div className="return-val">
                                    {
                                      BetslipType !== CONSTANT.FANCY_BETTING ?
                                        <Fragment>
                                          {
                                            Number(item.is_bookmaker) === 1 ? (
                                              _.round(Number(Utils.ThreeDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) * Number(item.stack_value), 3) || '--'
                                            ) : (
                                              _.round(Number(Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) * Number(item.stack_value), 2) || '--'
                                            )
                                          }
                                        </Fragment>
                                        :
                                        <Fragment>{_.round((Number(item.stack_value) * (Number(item.picked_odd.back_size_1) / 100)), 2) || '--'}</Fragment>
                                    }
                                  </div>
                                </div>
                                :
                                <div className='return-value-col'>
                                  <div className="return-val">
                                    {
                                      BetslipType !== CONSTANT.FANCY_BETTING ?
                                        <Fragment>
                                          {
                                            Number(item.is_bookmaker) === 1 ? (
                                              _.round((Number(Utils.ThreeDecimalVal(item.picked_odd.price)) * Number(item.stack_value)) - Number(item.stack_value), 3)
                                              || '--'
                                            ) : (
                                              _.round((Number(Utils.TwoDecimalVal(item.picked_odd.price)) * Number(item.stack_value)) - Number(item.stack_value), 2)
                                              || '--'
                                            )
                                          }
                                        </Fragment>
                                        :
                                        <Fragment>
                                          {
                                            _.round((Number(item.stack_value) * (Number(item.picked_odd.lay_size_1) / 100)), 2)
                                            || '--'
                                          }
                                        </Fragment>
                                    }
                                  </div>
                                </div>
                            }

                          </Col>
                          <Col className='col-2 p-1 '>
                            <div {...{ className: `${BetslipType === CONSTANT.FANCY_BETTING ? (Number(item.picked_odd.betting_type) === 1 ? "lagai" : "khai") : (Number(item.picked_odd.betting_type) === 1 ? "lagai" : "khai")}` }}>
                              {BetslipType !== CONSTANT.FANCY_BETTING ? (Number(item.is_bookmaker) === 1 ? Utils.ThreeDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price)) : Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) : item.picked_odd.price}
                            </div>

                          </Col>
                          <Col className='col-2 p-1'>
                            <div className='time'>
                              <Clock defaultSecond={defaultSecond} defaultSecondShow={true} refCallback={this.setClockRef} parentMethod={this.clearBetSlipWindow} />
                            </div>
                          </Col>
                        </Row>


                        {
                          isSingleBet &&
                          <AvalStacks {...{ idx: key }} />
                        }
                      </div>
                    )
                  })
                }

                {
                  (!_.isEmpty(SelectedOdds) && !isSingleBet) &&
                  <div className="betslip-list-footer">
                    <Row className="no-gutters">
                      <Col xs={12}>
                        <input
                          type="text"
                          placeholder='Amount'
                          className='stake-input'
                          value={MultipleBetStake}
                          onKeyDown={this.multipleBetHandleKey}
                          onChange={this.multipleBetStackHandle}
                        />
                        <div className='return-value-col'>
                          <div className="return--small">{t('Return')}</div>
                          <div className="return-val">{_.round(this.totalValue('odd') * Number(MultipleBetStake), 2)}</div>
                        </div>
                      </Col>
                    </Row>
                    <div className="betslip--footer">
                      {
                        _.map(BetslipStack, (item, key) => <a onClick={() => this.multipleBetStackClickHandle(item)} {...{ key }}>{item}</a>)
                      }
                    </div>
                  </div>
                }
              </div>
          }
          {
            !_.isEmpty(SelectedOdds) &&
            <div className="betslip-footer">
              {
                !CONSTANT.ONLY_SINGLE_BET &&
                <Fragment>
                  <i className="icon-trash" onClick={() => this.clearAllBet(BetslipType)} />
                  {
                    BetslipType !== CONSTANT.FANCY_BETTING &&
                    <Row>
                      <Col>{t('Total Rates')} : <b>{this.totalValue('odd')}</b></Col>
                    </Row>
                  }
                  <Row>
                    {
                      isSingleBet ?
                        <Col>{t('Total Amount')} : <b>{this.totalValue('stake') === 0 ? '0.00' : this.totalValue('stake')}</b></Col>
                        :
                        <Col>{t('Total Amount')} : <b>{MultipleBetStake === 0 ? '0.00' : MultipleBetStake}</b></Col>
                    }
                  </Row>
                </Fragment>
              }
              <Row>
                {/* <Col className="text-right"> */}
                <Col className="text-center d-flex">
                  <Button
                    color="primary"
                    className="mr-2 betslip_close"
                    onClick={() => this.handleRemoveSlip(0)}
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    // className="float-right betslip_submit"
                    className={(BetPosting
                      || this.isValidate()) ? "ml-2 betslip_close" : "ml-2 betslip_submit"}
                    block
                    disabled={
                      // (currentUser && currentUser.lock_betting !== 0) ||
                      BetPosting
                      || this.isValidate()
                      || (this.isDuplicateBetSlip() && !isSingleBet)
                      || (this.isLayMultiBet() && !isSingleBet)
                    }
                    onClick={() => this.PlaceBetHandler()}>{t('Place Bet')}&nbsp;
                    {/* <Clock defaultSecond={defaultSecond} defaultSecondShow={defaultSecondShow} refCallback={this.setClockRef} parentMethod={this.clearBetSlipWindow} /> */}
                  </Button>
                </Col>
              </Row>
            </div>
          }
        </div>
      </div>
    )
  }
}

Betslip.defaultProps = {
  fancy_betslip: '',
  _fancyHandleRemoveSlip: () => { }
}
const BetslipTrans = withTranslation()(Betslip)

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    isOpen: login.isOpen,
    openCollapse: app.openCollapse
  };
}

const mapDispatchToProps = dispatch => ({
  loginOpen: () => dispatch(loginOpen()),
  LoaderShow: (date) => dispatch(LoaderShow(date)),
  LoaderHide: () => dispatch(LoaderHide()),
  UpdateProfile: () => dispatch(UpdateProfile()),
  updateWinloss: (option) => dispatch(updateWinloss(option)),
  SlipToggle: (type) => dispatch(SlipToggle(type)),
  actionBetSlipRule: (messageRule) => dispatch(actionBetSlipRule(messageRule)),
  actionClearBetSlipWindow: (betsliphide) => dispatch(actionClearBetSlipWindow(betsliphide))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetslipTrans);
