import React, { Component, Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, Row, Col } from 'reactstrap';
import { OddSetingModal } from 'Modals';
import { _, Utils } from 'Helpers';
import { Images } from 'Constants';
export class BetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OddSetingModalShow: false
        }
    }
    modalShow = () => {
        this.setState({ OddSetingModalShow: true })
    }
    modalHide = () => {
        this.setState({ OddSetingModalShow: false })
    }
    toggle = () => {
        this.modalHide()
    }

    render() {
        const { BetBalance, BetRule, betModalHandler } = this.props;
        const { OddSetingModalShow } = this.state;
        const OddSetingModalProps = {
            isOpen: OddSetingModalShow,
            toggle: this.toggle,
        };
        let setDataOfBet = JSON.parse(localStorage.getItem('setDataOfBet'));
        let propertyNames = !_.isUndefined(setDataOfBet) && !_.isNull(setDataOfBet) && !_.isUndefined(setDataOfBet.response) && !_.isNull(setDataOfBet.response) && !_.isUndefined(setDataOfBet.response.data) && !_.isNull(setDataOfBet.response.data) && !_.isUndefined(setDataOfBet.response.data.runner_pl) && !_.isNull(setDataOfBet.response.data.runner_pl) ? Object.keys(setDataOfBet.response.data.runner_pl) : '';
        let propertyValues = !_.isUndefined(setDataOfBet) && !_.isNull(setDataOfBet) && !_.isUndefined(setDataOfBet.response) && !_.isNull(setDataOfBet.response) && !_.isUndefined(setDataOfBet.response.data) && !_.isNull(setDataOfBet.response.data) && !_.isUndefined(setDataOfBet.response.data.runner_pl) && !_.isNull(setDataOfBet.response.data.runner_pl) ? Object.values(setDataOfBet.response.data.runner_pl) : '';
        let merged = [];
        let afterP = [];
        if (!_.isUndefined(setDataOfBet) && !_.isNull(setDataOfBet) && !_.isUndefined(setDataOfBet.param) && !_.isNull(setDataOfBet.param) && (setDataOfBet.param.bookmaker_type == 2) && !_.isUndefined(propertyNames) && !_.isNull(propertyNames) && (propertyNames !== '') && !_.isUndefined(propertyValues) && !_.isNull(propertyValues) && (propertyValues !== '')) {
            JSON.parse(setDataOfBet.extra_data.item.runners).map((x) =>
                afterP.push({ selectionId: x.selectionId, runnerName: x.runnerName })
            )
            if (!_.isEmpty(afterP))
                propertyValues.map((item, idx) =>
                    merged.push({
                        "selectionId": propertyNames[idx], "amount": item, "runnerName": afterP.filter((name) => {
                            return name.selectionId == propertyNames[idx]
                        })
                    })
                )
        }



        if (BetBalance && BetBalance.includes('You are Exceeding the Max Profit')) {
            let priceSplit = BetBalance.split(' ')
            let data = priceSplit.filter((price) => price >= 0 && price)
            var MFmaxprice = data.reduce((a, b) => Math.max(a, b))
        }
        const ModalProps = {
            isOpen: true,
            size: 'sm',
            className: 'custom-modal modal-middle',
            fade: false
        }

        return (
            <Modal {...ModalProps}>
                <i className="close-btn icon-cancel" onClick={() => betModalHandler()} />
                <ModalHeader>BET MESSAGE</ModalHeader>
                <ModalBody>
                    <div className="row pd-t-15 pd-b-15" style={{
                        overflow: 'scroll',
                        maxHeight: '66vh'
                    }}>
                        <div className="col-12 bet_succ">
                            {

                                BetBalance.startsWith('You are Exceeding the Max') ?
                                    <Fragment>
                                        {BetBalance.endsWith('Market.') &&
                                            (<span>
                                                You are Exceeding the Max Profit Limit.
                                                <br />
                                                Your Max Profit Limit is {MFmaxprice} for this Market.
                                            </span>)
                                        }
                                        {
                                            BetBalance.endsWith('Fancy.') &&
                                            (<span>
                                                You are Exceeding the Max Profit Limit.
                                                <br />
                                                Your Max Profit Limit is {MFmaxprice} for this Fancy.
                                            </span>)
                                        }
                                    </Fragment>
                                    : BetBalance}
                        </div>
                        <div className={'col-12 second-msg ' + (BetBalance == 'Bet(s) has been placed successfully.' ? 'text_msg_color' : ' ')}>
                            {BetBalance == 'Bet(s) has been placed successfully.' && !_.isEmpty(setDataOfBet) ?
                                <div className={'row'}>
                                    <div className="col-12">
                                        {`You Placed ${setDataOfBet.param.stake} ₹ bet.`}
                                    </div>
                                    <div className="col-12">
                                        {`At the rate of ${setDataOfBet.param.bookmaker_type == 3 ?
                                            (setDataOfBet.extra_data.picked_odd.child_session_type_id == 10 ? '1' :
                                                setDataOfBet.param.line)
                                            : setDataOfBet.response.data.bet_price
                                            } ${setDataOfBet.param.bookmaker_type == 3 ?
                                                (setDataOfBet.extra_data.picked_odd.child_session_type_id == 10 ?
                                                    (setDataOfBet.param.betting_type == 2 ? 'EVEN' : 'ODD')
                                                    :
                                                    (setDataOfBet.param.betting_type == 2 ? 'NO' : 'YES')
                                                )
                                                :
                                                (setDataOfBet.param.betting_type == 2 ? 'KHAI' : 'LAGAI')


                                            } ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : setDataOfBet.extra_data.betOnRun.runnerName}.`}
                                    </div>
                                    {
                                        setDataOfBet.param.bookmaker_type == 3 ?
                                            // <div className="col-12">
                                            //     {`Your ${setDataOfBet.param.betting_type == 2 ? 'profit' : 'loss'} is 
                                            //     ${setDataOfBet.extra_data.whatWeGet} with ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : setDataOfBet.extra_data.betOnRun.runnerName}.`}
                                            // </div>

                                            <>
                                                {setDataOfBet.param.betting_type == 2 ?
                                                    <>
                                                        <div className="col-12">

                                                            {`Your ${Number(setDataOfBet.response?.data?.runner_pl?.No) > 0 ? 'profit' : 'loss'} is 
                                       ${setDataOfBet.response?.data?.runner_pl?.No} with ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : setDataOfBet.extra_data.betOnRun.runnerName}.`}
                                                        </div>
                                                        <div className="col-12">
                                                            {`Your ${Number(setDataOfBet.response?.data?.runner_pl?.Yes) > 0 ? 'profit' : 'loss'} is 
                                       ${setDataOfBet.response?.data?.runner_pl?.Yes} with ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : setDataOfBet.extra_data.betOnRun.runnerName}.`}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-12">
                                                            {`Your ${Number(setDataOfBet.response?.data?.runner_pl?.Yes) > 0 ? 'profit' : 'loss'} is 
                                            ${setDataOfBet.response?.data?.runner_pl?.Yes} with ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : setDataOfBet.extra_data.betOnRun.runnerName}.`}
                                                        </div>
                                                        <div className="col-12">

                                                            {`Your ${Number(setDataOfBet.response?.data?.runner_pl?.No) > 0 ? 'profit' : 'loss'} is 
                                            ${setDataOfBet.response?.data?.runner_pl?.No} with ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : setDataOfBet.extra_data.betOnRun.runnerName}.`}
                                                        </div>
                                                    </>
                                                }
                                            </>


                                            :
                                            (merged.map((item, x) => {
                                                return (
                                                    <div className="col-12" key={x}>
                                                        {`Your ${setDataOfBet.param.bookmaker_type == 3 ?
                                                            (setDataOfBet.param.betting_type == 2 ? 'loss' : 'profit')
                                                            :
                                                            (Number(item.amount) < 0 ? 'loss' : 'profit')
                                                            } is ${Utils.TwoDecimalVal(item.amount)} with ${typeof setDataOfBet.extra_data.betOnRun == 'string' ? setDataOfBet.extra_data.betOnRun : item.runnerName[0].runnerName}.`}
                                                    </div>
                                                )
                                            })
                                            )
                                    }
                                </div >
                                :
                                BetRule.startsWith('आप अधिकतम लाभ सीमा') ?
                                    <span>
                                        आप अधिकतम लाभ सीमा को पार कर रहे हैं।
                                        <br />
                                        इस बाजार के लिए आपकी अधिकतम लाभ सीमा {MFmaxprice} है|
                                    </span>
                                    :
                                    BetRule
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-space-between">
                    <Button size="sm" color="primary" className={`float-left odd_setting`} onClick={() => { this.modalShow() }}>Odd Setting</Button>

                    <Button size="sm" color="primary" className={`float-right ok `} onClick={() => betModalHandler()}>OK</Button>
                </ModalFooter>
                {OddSetingModalShow && <OddSetingModal {...OddSetingModalProps} />}
            </Modal>
        )
    }
}

export default BetModal